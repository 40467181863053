import { createContext, memo, useContext, useEffect, useRef, useState } from "react";

const AccordionContext = createContext();
const Accordion = memo(({ children }) => {
  const [state, setState] = useState(0);
  return (
    <AccordionContext.Provider value={{ state, setState }}>
      {children}
    </AccordionContext.Provider>
  );
});

const Content = memo(({ value, children,title }) => {
  const { state, setState } = useContext(AccordionContext);
  const ref=useRef();
  useEffect(()=>{ setState(1) },[]);
  return (
    <>
      <button
        className="cust-header w-100 d-flex align-items-center justify-content-between py-1"
        onClick={() => setState(value)}
      >
        <p className="general-text">{title}</p>
        <img src={require("../assets/images/Expand_Icon.png")} className={`expand-icon ${value===state?"active":""}`} alt="" />
      </button>
      <div className="cust-body" style={{height:value==state?`${ref.current?.scrollHeight}px`:0}} ref={ref}>{children}</div>
    </>
  );
});

Accordion.Content = Content;

export default Accordion;
