const KitchenItem = ({ img, name, qty,styleClass }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column"
    >
      <img
        src={require(`../../assets/images/${img}.png`)}
        alt=""
        className="replenishment-img"
        // style={{width:"20px"}}
      />
      <p className="general-text-small pt-1">{name}</p>
      <p className={`general-text-grey text-center ${styleClass}`}>{qty}</p>
    </div>
  );
};

export default KitchenItem;
