import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useContext, useEffect, useMemo, useState } from "react";
import Counter from "../Counter";
import Temperature from "./Temperature";
import MeterChart from "./MeterChart";
import { SocketContext } from "../../context/SocketProvider";

const EnergyOverview = ({ value }) => {
  const [temperature, setTemperature] = useState("");
  const [coLevel, setCOLevel] = useState(""); //
  const [ethanolLevel, setEthanolLevel] = useState(""); //
  const { socket } = useContext(SocketContext);

  const [options, setOptions] = useState({
    chart: {
      type: "gauge",
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      backgroundColor: "transparent",
      events: {
        render: function () {
          this.reflow();
        },
      },
      animation: true,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    tooltip: {
      enabled: false,
    },
    pane: {
      startAngle: -130,
      endAngle: 130,
      center: ["50%", "50%"],
      size: "85%",
      background: {
        backgroundColor: "gray",
        borderWidth: 0,
        innerRadius: "107%",
        shape: "arc",
      },
    },
    yAxis: {
      min: 0,
      max: 1000,
      tickPixelInterval: 5,
      tickPosition: "inside",
      tickColor: {
        linearGradient: [700, 500, -500, 0],
        stops: [
          [0.4, "#47464D"],
          [0.5, "#F8C63F"],
          [0.4, "#3DE54F"],
        ],
      },

      tickLength: 20,
      tickWidth: 4,
      minorTickInterval: null,
      startOnTick: true,
      endOnTick: true,
      labels: {
        distance: 20,
        style: {
          color: "#fff",
          fontSize: "var(--fs20)",
        },
        formatter: function () {
          if (this.isLast || this.isFirst) {
            return this.value + "°";
          }
        },
      },
      plotBands: [
        {
          from: 0,
          to: 200,
          color: "transparent",
          thickness: 0,
        },
      ],
    },
    series: [
      {
        name: "Speed",
        data: [value.chartData],
        tooltip: {
          valueSuffix: "kWh",
        },
        dataLabels: {
          useHTML: true,
          format:
            "<div class='kWhDiv' style='text-align:center;'><p style='color:#000;font-size:var(--fs40); margin-bottom:0.5rem;'>{y}</p><span style='font-size:var(--fs20);color:var(--secondary-black);font-weight:500;line-height:100%'>kWh</span></div>",
          borderWidth: 0,
          verticalAlign: "middle",

          y: 0,
          color: "gray",
          style: {
            fontSize: "var(--fs20)",
          },
        },
      },
    ],
    plotOptions: {
      gauge: {
        dataLabels: {
          enabled: true,
          useHTML: true,
          borderWidth: 0,
          style: {
            borderWidth: 0,
          },
        },
        dial: {
          radius: "120%",
          backgroundColor: "var(--color7)",
          borderColor: "#fff",
          borderWidth: 0,
          baseWidth: 0,
          topWidth: 25,
          baseLength: "90%",
          pathFormat: "triangle",
        },
        pivot: {
          radius: 0,
          borderWidth: 0,
          borderColor: "#fff",
          backgroundColor: "#1A1A1A",
        },
      },
    },
  });

  useEffect(() => {
    setOptions({
      ...options,
      series: [
        {
          name: "Speed",
          data: [value.chartData],
          tooltip: {
            valueSuffix: "kWh",
          },
          dataLabels: {
            useHTML: true,
            format:
              "<div class='kWhDiv' style='text-align:center;'><p style='color:#000;font-size:var(--fs40); margin-bottom:0.5rem;'>{y}</p><span style='font-size:var(--fs20);color:var(--secondary-black);font-weight:500;line-height:100%'>kWh</span></div>",
            borderWidth: 0,
            verticalAlign: "middle",

            y: 0,
            color: "gray",
            style: {
              fontSize: "var(--fs20)",
            },
          },
        },
      ],
    });
  }, [value]);

  const countData = useMemo(() => {
    return [
      { name: "Guests", count: value.guest },
      { name: "Workers", count: value.worker },
    ];
  }, [value]);

  useEffect(() => {
    if (!socket) {
      console.log("Socket is not initialized");
      return;
    }
    const temperatureUpdateHandler = (data) => {
      console.log("TEMP:", data);
      setTemperature(Number(data.temperature).toFixed(1));
    };
    const coUpdateHandler = (data) => {
      console.log("CO:", data);
      setCOLevel(Number(data.co).toFixed(1));
    };
    const ethanolUpdateHandler = (data) => {
      console.log("ETHANOL:", data);
      setEthanolLevel(Number(data.ethanol).toFixed(1));
    };

    socket.on("temperature_update", temperatureUpdateHandler);
    socket.on("gas_update", coUpdateHandler);
    socket.on("alcohol_update", ethanolUpdateHandler);

    return () => {
      socket.off("temperature_update", temperatureUpdateHandler);
      socket.off("gas_update", coUpdateHandler);
      socket.off("alcohol_update", ethanolUpdateHandler);
    };
  }, []);

  return (
    <div className="left-item3 subcard flex-grow-1 d-flex flex-column justify-content-between">
      <div className="d-flex ">
        <div className="w-60 ">
          <div className="guage-chart d-flex mx-auto">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              containerProps={{
                style: {
                  height: "100%",
                  width: "100%",
                  paddingInline: "0",
                },
              }}
            />
          </div>
          <div className="d-flex justify-content-between mx-auto">
            {countData.map(({ name, count }, idx) => {
              return (
                <div
                  className="d-flex align-items-center flex-column energy-list w-50"
                  key={idx}
                >
                  <p className="general-text-grey">{name}</p>
                  <Counter value={count} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="range-con w-40 d-flex align-items-start flex-column justify-content-evenly">
          <div className="d-flex align-items-center gap-3">
            <div className="range-img d-flex align-items-center justify-content-center">
              <Temperature value={value.temperature} />
            </div>
            <div>
              <h3 className="general-text mb-1">{value.temperature}° C</h3>
              <p className="general-text-grey">Temperature</p>
            </div>
          </div>
          <MeterChart value={value.humidity} placeholder={"Humidity"}/>
          <MeterChart value={value.carbon} placeholder={"Carbon Monoxide"}/>
          <MeterChart value={value.predictiveEnergy} placeholder={"Predictive Energy"} />
        </div>
      </div>
    </div>
  );
};

export default EnergyOverview;
