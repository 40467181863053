import { createContext, useState } from "react"

export const AppContext=createContext();
const AppProvider = ({children}) => {
    const [widgetData,setWidgetData]=useState(['KitchenAirBalance', 'TotalConsumption', 'ReplenishmentAlert', 'PredictiveMaintenance']);
    const [alert,setAlert]=useState(false);
  return (
    <AppContext.Provider value={{widgetData,setWidgetData,setAlert,alert}}>{children}</AppContext.Provider>
  )
}

export default AppProvider