import { useEffect, useMemo, useState } from "react";

const Bin = ({value}) => {
    const [state, setState] = useState(0.98);   
  useEffect(() => {
    const stateInterval = setInterval(() => {
      if (state < value) {
        setState((state) => Math.ceil(state) + 1);
      }
    }, 20);
    return () => {
      clearInterval(stateInterval);
    };
  }, [state]);
  
  const color=useMemo(()=>{
    // setState(0);
    if(state===0){
      return "transparent"
    }
    if(state<=45){
      return "#13d906"
    }
    if(state>45&&state<=75){
      return " var(--color7)"
    }
    if(state>75&&state<=100){
      return "red"
    }
},[state])

  return (
    <svg 
    className="w-50"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 194.593 279.94"
                >
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="0.5"
                      y1={`0.${100- Math.ceil(state)}`}
                      // y1={`0.0`}
                      x2="0.5"
                      y2="0.99"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop
                        offset="0"
                        stopColor="#339346"
                        stopOpacity="0"
                      ></stop>
                      <stop
                        offset="0"
                        stopColor={color}
                        className="stop_color"
                      ></stop>
                      <stop
                        offset="0.505"
                        stopColor={color}
                        className="stop_color"
                      ></stop>
                      <stop
                        offset="1"
                        stopColor={color}
                        className="stop_color"
                      ></stop>
                    </linearGradient>
                  </defs>

                  <g
                    id="Group_336"
                    data-name="Group 336"
                    transform="translate(13106.364 16139.5)"
                  >
                    <g
                      id="Bin_Illustration"
                      transform="translate(976.5 87.051)"
                    >
                      <path
                        id="Bin_Illustration-2"
                        data-name="Bin_Illustration"
                        d="M136.637,273.942v-9.3l-4.444,0c-10.15.051-22.936.081-36.831.081-11.779,0-22.762-.022-32.016-.059l-7.789,0v9.271h-17.3V264.46a23.677,23.677,0,0,1-2.657-.112,7.728,7.728,0,0,1,1.264-.078,9.078,9.078,0,0,1-8.355-8.086L7.493,60.238H5.325a5.325,5.325,0,1,1,0-10.65H6.611c0-.033,1.012-.066,2.915-.1L32.015,0H159.238l21.937,49.5a21.862,21.862,0,0,1,2.214.084v0a5.325,5.325,0,0,1,.122,10.644L164.151,256.11a9.078,9.078,0,0,1-9.033,8.185h-.593a2.5,2.5,0,0,1,.591.054,7.022,7.022,0,0,1-1.179.075v9.519Z"
                        transform="translate(-14079.797 -16223.551)"
                        fill="#fff"
                        opacity="0.1"
                      ></path>
                      <g
                        id="Group_281"
                        data-name="Group 281"
                        transform="translate(-14079.863 -16223.551)"
                      >
                        <path
                          id="Union_3"
                          data-name="Union 3"
                          d="M136.637,273.942v-9.3l-4.444,0c-10.15.051-22.936.081-36.831.081-11.779,0-22.762-.022-32.016-.059l-7.789,0v9.271h-17.3V264.46a23.677,23.677,0,0,1-2.657-.112,7.728,7.728,0,0,1,1.264-.078,9.078,9.078,0,0,1-8.355-8.086L7.493,60.238H5.325a5.325,5.325,0,1,1,0-10.65H6.611c0-.033,1.012-.066,2.915-.1L32.015,0H159.238l21.937,49.5a21.862,21.862,0,0,1,2.214.084v0a5.325,5.325,0,0,1,.122,10.644L164.151,256.11a9.078,9.078,0,0,1-9.033,8.185h-.593a2.5,2.5,0,0,1,.591.054,7.022,7.022,0,0,1-1.179.075v9.519Z"
                          transform="translate(-0.001 0)"
                          opacity="0.05"
                        ></path>
                        <path
                          id="Union_5"
                          data-name="Union 5"
                          d="M136.639,273.94v-9.294l-4.733,0c-10.1.05-22.78.079-36.544.079-11.7,0-22.612-.021-31.827-.058l-7.976,0v9.269h-17.3v-9.48a23.677,23.677,0,0,1-2.657-.112,7.747,7.747,0,0,1,1.266-.078,9.074,9.074,0,0,1-8.356-8.086L7.49,60.237H5.325a5.325,5.325,0,1,1,0-10.65H6.611c0-.033,1.012-.066,2.912-.1L32.015,0H159.238l21.937,49.5a21.866,21.866,0,0,1,2.215.084h0a5.325,5.325,0,0,1,.122,10.644l-19.36,195.879a9.075,9.075,0,0,1-9.036,8.184h-.591a2.5,2.5,0,0,1,.591.054,7.034,7.034,0,0,1-1.18.075v9.517Z"
                          transform="translate(-0.001 0)"
                          fill="url(#linear-gradient)"
                        ></path>
                      </g>
                    </g>
                    <text
                      id="_67_"
                      data-name="67%"
                      transform="translate(-13051.363 -15944.627)"
                      fill="#fff"
                      fontSize="46"
                      fontFamily="GTWalsheimPro-Medium, GT Walsheim Pro,gtBold"
                      fontWeight="500"
                    >
                      <tspan x="0" y="0" className="tspanText">{value}%</tspan>
                    </text>
                  </g>
                </svg>
  )
}

export default Bin