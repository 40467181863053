
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
// import Header from "../components/Header";
import AppProvider from "../context/AppProvider";

const Layout = () => {
  return (
    <AppProvider>
    <main>
        <Sidebar/>
        <section className="container-section">
        {/* <Header/> */}
        <Outlet/>
        </section>
    </main>
    </AppProvider>
  )
}
export default Layout;