import { useEffect } from "react"
import KitchenItem from "./KitchenItem"
import SwitchKey from "../SwitchKey"

const KitchenCon = ({item,kitchenEquipments,setActualData,actulData,setCheckAll}) => {

  useEffect(()=>{
    const areEqual= kitchenEquipments.map(item=>{
      if(actulData.includes(item.id)){
       return true
      }
      else{
        return false
      }
    }).every(item=>item===true);
    if (areEqual) {
      setCheckAll(true);
    }
  },[actulData]);

const facilityCardToggle=(e)=>{
  const {value,checked}=e.target;
  if(checked){
    setActualData(data=>[...data,value])
  }
  else{
    setActualData(data=>data.filter(item=>item!==value))
      setCheckAll(false);
  }
}
  return (
    <div className='d-flex align-items-center flex-column sub-con'>
            <KitchenItem {...item} />
            <SwitchKey rotate="rotate(90deg)" facilityCardToggle={facilityCardToggle} id={item.id} checked={actulData.includes(item.id)}/>
          </div>
  )
}

export default KitchenCon