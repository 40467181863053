import { Children, memo, useState } from "react";

 function Tabs ({children}) {
  const [state,setState]=useState(1);
  const tabs = Children.toArray(children);
  return (
    <div className="tab">
            <div className="d-flex align-items-center tab-head gap-3 pb-2 ">
              {
                tabs.map((item,idx)=>{
                 return <button key={idx}
                  onClick={()=>setState(item.props.value)}
                   type="button" className={item.props.value===state?"active":""}>{item.props.header}</button>
                })
              }
            </div>
            <div className="tab-body">
              {
                tabs.find(item=>{
                  if(item.props.value===state){
                    return item.props.children
                  }
                })
              }
            </div>
          </div>
  )
}

const Tab=({children})=>{
   return <>{children}</>
}
Tabs.Tab=Tab;

export default Tabs;
