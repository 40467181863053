import { useContext, useEffect, useState } from "react";
import SwitchKey from "../SwitchKey";
import { AppContext } from "../../context/AppProvider";

const FacilityCard = ({img,name,id,socket,signal}) => {
  const [facility,setFacility]=useState(false);
  const [checked,setChecked]=useState(false);
  const {setAlert}=useContext(AppContext);
  const facilityCardToggle=(e)=>{
    setChecked(e.target.checked)
    socket.emit("facility",{
      id:e.target.value,
      active:e.target.checked
    })
    if(id==="11458"){
        setAlert(e.target.checked)
    }
  }
  useEffect(()=>{
    const recivedFac=(data) => {
      if(data.id===id){
        setFacility(data.active)
      }
    }
    socket.on("receive-facility",recivedFac)
    return()=>{
      socket.off("receive-facility",recivedFac)
    }
  },[]);

  return (
    <div className={`d-flex align-items-start justify-content-between flex-column facility-card gap-2 ${facility?"active":"inactive"}`}>
      
      <div className="d-flex align-items-start w-100 justify-content-between">
        <div className="d-flex align-items-start justify-content-start flex-column gap-2">
        <img src={require(`../../assets/images/${(!facility||!checked)?img:`${img}_active`}.png`)} className={`facility-img `} alt="" />
        <div className="d-flex align-items-center gap-2 justify-content-start w-100">
       {
        signal.map((item,idx)=>{
            if(item==="zigbee"){
              return <img key={idx} src={require(`../../assets/images/zigbee.png`)} alt="" />
            }
            else{
              return <img key={idx} src={require(`../../assets/images/matter.png`)} alt="" />
            }
        }
          )
        }
        </div>
        </div>
        <SwitchKey facilityCardToggle={facilityCardToggle} id={id}/>
      </div>
        <p className="general-text-grey text-white text-nowrap">{name}</p>
    </div>
  );
};

export default FacilityCard;
