import { useEffect } from "react";

const SvgIncrease = ({ value,viewBox,svgId,children }) => {
  useEffect(() => {
    // const stateInterval = setInterval(() => {
    //   if (state < value) {
    //     setState((state) => Math.ceil(state) + 1);
    //   }
    // }, 20);
    // return () => {
    //   clearInterval(stateInterval);
    // };
    let binStatusPercent = value;
    let i = 100;
    let lg = document.getElementById(svgId);
    const UpdateBinData = () => {
      let remaining = 100 - binStatusPercent;
      i = i - 1;
      if (i / 100 >= remaining / 100) {
        lg.setAttribute("y1", i / 100);
        setTimeout(UpdateBinData, 30);
      } else {
        lg.setAttribute("y1", remaining / 100);
        i = 100;
      }
    }
    UpdateBinData()
  }, [value]);

  return (
    <svg className="w-50 device-svg-modal" viewBox={viewBox}>
      <defs>
        <linearGradient
          id={svgId}
          x1="0.5"
          y1={`0.98`}
          x2="0.5"
          y2="0.99"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#339346" stopOpacity="0"></stop>
          <stop offset="0" stopColor="#333893" className="stop_color"></stop>
          <stop
            offset="0.505"
            stopColor="#333893"
            className="stop_color"
          ></stop>
          <stop offset="1" stopColor="#333893" className="stop_color"></stop>
        </linearGradient>
      </defs>
      {children}
      <text
        id="_67_"
        data-name="67%"
        transform="translate(-13051.363 -15944.627)"
        fill="#fff"
        fontSize="46"
        fontFamily="GTWalsheimPro-Medium, GT Walsheim Pro,gtBold"
        fontWeight="500"
      >
        <tspan x="0" y="0" className="tspanText">
          {value}%
        </tspan>
      </text>
    </svg>
  );
};

export default SvgIncrease;
