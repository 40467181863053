import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useMemo } from "react";

const MeterChart = ({ value, placeholder }) => {
  const meterChartOpt = useMemo(() => {
    const colorForChart = () => {
      if (value <= 45) {
        return "#13d906";
      }
      if (value > 45 && value <= 75) {
        return " var(--color7)";
      }
      if (value > 75 && value <= 100) {
        return "red";
      }
    };
    return {
      chart: {
        type: "gauge",
        margin: [0, 0, 0, 0],
        backgroundColor: "transparent",
        animation: true,
      },
      title: null,
      yAxis: {
        min: 0,
        max: 100,
        tickPixelInterval: 100,
        tickPosition: "outside",
        tickColor: "#FFFFFF",
        tickLength: 0,
        tickWidth: 0,
        minorTickInterval: null,
        labels: {
          enabled: false,
        },
        lineWidth: 0,
        plotBands: [
          {
            from: 0,
            to: value,
            color: colorForChart(),
            thickness: "15%",
          },
        ],
      },
      pane: {
        size: "100%",
        center: ["50%", "75%"],
        startAngle: -120,
        endAngle: 120,
        background: [
          {
            backgroundColor: "#303030",
            innerRadius: "85%",
            outerRadius: "100%",
            shape: "arc",
            borderWidth: 0,
            borderRadius: 80,
            color: "#fffffff",
          },
        ],
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        gauge: {
          dataLabels: {
            enabled: true,
            useHTML: true,
            borderWidth: 0,
            style: {
              borderWidth: 0,
            },
          },
          dial: {
            radius: "70%",
            backgroundColor: colorForChart(),
            borderColor: colorForChart(),
            borderWidth: 2,
            baseWidth: 4,
            topWidth: 0,
            baseLength: "0%",
            rearLength: "0%",
          },
          pivot: {
            borderWidth: 2,
            borderColor: colorForChart(),
            backgroundColor: "#1A1A1A",
            radius: 4,
          },
        },
      },
      series: [
        {
          name: "kWh",
          data: [value],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      credits: {
        enabled: false,
      },
    };
  }, [value]);

  return (
    <div className="d-flex align-items-center gap-3">
      <div className="range-chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={meterChartOpt}
          containerProps={{
            style: {
              height: "100%",
              width: "100%",
              paddingInline: "0",
            },
          }}
        />
      </div>
      <div>
        <h3 className="general-text mb-1">{value}%</h3>
        <p className="general-text-grey">{placeholder}</p>
      </div>
    </div>
  );
};

export default MeterChart;
