import Modal from "../Modal";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Bin from "./Bin";

const Binlevel = ({ item }) => {
  const analyticsChartOption = {
    chart: {
      type: "area",
      backgroundColor: "rgba(255, 255, 255, 0.0)",
    },
    title: {
      text: "",
      align: "left",
      floating: true,
      style: {
        fontWeight: "600",
        fontSize: "20px",
      },
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "12 Dec",
        "13 Dec",
        "14 Dec",
        "15 Dec",
        "16 Dec",
        "17 Dec",
        "18 Dec",
      ],
      labels: {
        style: {
          fontSize: "clamp(8px , 13vw*100/1920, 13px)",
          color: "#ffffff75",
        },
      },
      gridLineWidth: 1,
      gridLineColor: "#ffffff50",

      // tickPositions: [0, 2, 4, 6, 8, 10],
    },
    yAxis: {
      // gridLineWidth: 1,
      title: {
        text: "",
      },
      gridLineColor: "#ffffff50",
      min: 0,
      max: 50,

      labels: {
        style: {
          fontSize: "clamp(8px , 13vw*100/1920, 13px)",
          color: "#ffffff75",
        },
        formatter: function () {
          return this.axis.defaultLabelFormatter.call(this) + "kg";
        },
      },
    },
    tooltip: {
      shared: true,
      headerFormat: "<b>{point.x}  Status  </b><br>",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        connectNulls: true,
      },

      areaspline: {
        fillOpacity: 0.5,
      },
    },
    series: [
      {
        index: 1,
        name: "kg",
        color: "#fff",
        gridlinecolor: "#fffff00",
        data: [10, 20, 30, 20, 20, 30, 40],

        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "#ffffff55"],
            [1, "#ffffff00"],
          ],
        },
        marker: {
          radius: 8,
        },
      },
    ],
  };
  const color=(value)=>{
    if(value<=45){
      return "#13d906"
    }
    if(value>45&&value<=75){
      return " var(--color7)"
    }
    if(value>75&&value<=100){
      return "red"
    }
  };
  return (
    <div className=" w-100">
      <p className="general-text-grey">{item.bin}</p>
      <div className="d-flex align-items-center gap-3 pt-1">
        {item.data.map((item, idx) => {
          const id=item+new Date().valueOf();
          return (
            <Modal key={idx}>
              <Modal.ToggleButton>
                <button type="button" className="d-flex align-items-center gap-1 flex-column">
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "17px" }} viewBox="0 0 22.85 26.151">
                <defs>
                    <linearGradient
                      id={`linear-gradient-bin-${id}`}
                      x1="0.5"
                      y1={`0.${100-item}`}
                      x2="0.5"
                      y2="0.99"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop
                        offset="0"
                        stopColor="rgb(49, 48, 56)"
                      ></stop>
                      <stop
                        offset="0"
                        stopColor={color(item)}
                        className="stop_color"
                      ></stop>
                      <stop
                        offset="0.505"
                        stopColor={color(item)}
                        className="stop_color"
                      ></stop>
                      <stop
                        offset="1"
                        stopColor={color(item)}
                        className="stop_color"
                      ></stop>
                    </linearGradient>
                  </defs>
                  <g id="Group_7526" data-name="Group 7526" transform="translate(0)" >
                    <path id="Path_9728" fill="#313038" data-name="Path 9728" d="M-1061.012-1149.1h-10.324c-.815,0-1.1-.28-1.1-1.087,0-.417,0-.835,0-1.252a.834.834,0,0,1,.935-.934c1.86,0,3.72-.008,5.579,0,.3,0,.506-.058.6-.365a1.809,1.809,0,0,1,2.1-1.3c1.629.072,3.264.027,4.9.015a1.308,1.308,0,0,1,1.4.88c.22.642.607.806,1.27.784,1.681-.056,3.365-.02,5.048-.018.717,0,1.01.294,1.014,1.008,0,.43,0,.86,0,1.29a.861.861,0,0,1-.983.974c-1.43,0-2.859,0-4.289,0Z" transform="translate(1072.438 1154.041)" />
                    <path id="Path_9727" fill={`url(#linear-gradient-bin-${id})`} data-name="Path 9727" d="M-1050.883-1067.47h19.572c-.026.5-.046.974-.076,1.449q-.258,4.091-.519,8.182c-.157,2.513-.3,5.027-.465,7.539a2.468,2.468,0,0,1-2.573,2.4q-6.151.007-12.3,0a2.484,2.484,0,0,1-2.578-2.441q-.312-5.077-.618-10.154c-.134-2.172-.279-4.343-.418-6.515C-1050.87-1067.145-1050.874-1067.283-1050.883-1067.47Z" transform="translate(1052.52 1074.045)" />
                  </g>
                </svg>
                <p className="general-text-grey d-flex text-nowrap bin-per-text">{item}%</p>
                </button>
              </Modal.ToggleButton>
              <Modal.Content title={"Dining Dust Bin"} width={"70%"}>
                <div className="d-flex ">
                  <div className="w-60 dustbin-con">
                    <div className="d-flex align-items-center gap-5">
                      {["90kg", "Recycle", "10 Dec 2023"].map((item, idx) => (
                        <div key={idx}>
                          <h2 className="general-text mb-0">{item}</h2>
                          <p className="general-text-grey">Total Trash</p>
                        </div>
                      ))}
                    </div>
                    <div className="line-chart-out-con h-100 pt-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h1 className="general-text">Daily Bin Status</h1>
                        <select name="" id="" className="form-select select-list mw-100 general-text bg-transparent px-2 py-1 rounded">
                          <option value="" className="">Last 2 Week</option>
                          <option value="" className="">Last 2 Week</option>
                        </select>
                      </div>
                      <div className="w-100 flex-grow-1 dust-bin-chart">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={analyticsChartOption}
                          containerProps={{
                            style: {
                              height: "100%",
                              width: "100%",
                              paddingInline: "0",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-40 d-flex align-items-center justify-content-center flex-column gap-4">
                <Bin value={item}></Bin>
                <button type="button"className="general-text-grey rounded req-btn py-2 px-4">
                  Request to Pick-up
                </button>
                  </div>
                </div>
              </Modal.Content>
            </Modal>
          );
        })}
      </div>
    </div>
  );
};

export default Binlevel;
