import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import KitchenItem from "../components/dashboard/KitchenItem";
import FacilityCard from "../components/dashboard/FacilityCard";
import Tabs from "../components/Tabs";
import { useContext, useMemo, useRef, useState } from "react";
import Binlevel from "../components/dashboard/Binlevel";
import { DropDown } from "../components/DropDown";
import EnergyOverview from "../components/dashboard/EnergyOverview";
import Accordion from "../components/Accordion";
import HighEfficientDevices from "../components/dashboard/HighEfficientDevices";
import Modal from "../components/Modal";
import { useEffect } from "react";
import TotalConsumption from "../components/dashboard/TotalConsumption";
import { AppContext } from "../context/AppProvider";
import { SocketContext } from "../context/SocketProvider";
import PredictiveMaintenance from "../components/dashboard/PredictiveMaintenance";
import SvgIncrease from "../components/dashboard/SvgIncrease";

highchartsMore(Highcharts);
solidGauge(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

const Dashboard = () => {
  const {socket}=useContext(SocketContext);
  const [foodWasteDay, setFoodWasteDay] = useState(0);
  const [location, setlocation] = useState(0);
  const [totalConsumption,setTotalConsumption]=useState(false);
  const {widgetData,alert}=useContext(AppContext);
  useEffect(()=>{
    const handleResponsive=()=>{
       if(window.innerWidth>=1199){
        setTotalConsumption(false)
      }
      else{
         setTotalConsumption(true)
       }
    }
    handleResponsive()
    window.addEventListener("resize",handleResponsive)
    return ()=>window.removeEventListener("resize",handleResponsive)
  },[])

  const replenishmentData = [
    {
      img: "Icon_replenish_1",
      name: "Soft Drinks",
      qty: "950 litres",
      viewBox:"0 0 146.689 272.943",
      svgId:"softsvgtest",
      value:94,
      svg: <g id="Group_7953" data-name="Group 7953" transform="translate(16620 12704)">
      <path id="Softdrinks_Icon" d="M-703.043,195.939c-.855,10.007-1.695,19.507-2.473,29.013q-3.4,41.519-6.756,83.042-1.627,19.891-3.347,39.775c-.377,4.378-1.656,5.524-6.191,5.527q-44.206.028-88.411,0c-4.843,0-6.036-1.285-6.438-6.15q-4.048-49.049-8.153-98.091c-1.442-17.506-2.794-35.02-4.235-53.139-1.967,0-3.845.028-5.723-.007-2.8-.051-4.422-1.543-4.456-4.3-.1-7.753-.085-15.508-.028-23.261.023-2.992,1.848-4.443,4.7-4.576,2.288-.107,4.584-.022,7.191-.022,0-4.51-.029-8.5.008-12.488.045-4.906,1.57-6.457,6.557-6.468,15.64-.035,31.281-.013,46.92-.014h4.36c3.72-12.85,7.253-25.5,11.13-38.049a13.629,13.629,0,0,1,4.383-6.4c8.073-6.233,16.409-12.125,24.677-18.1,3.454-2.5,6.044-2.46,7.816-.031,1.748,2.4.939,5-2.415,7.45-7.4,5.413-14.926,10.666-22.179,16.27a12.942,12.942,0,0,0-4.229,5.992c-3.339,10.7-6.313,21.509-9.587,32.869h4.367q21.715,0,43.429,0c6.016.005,7.254,1.261,7.267,7.266.008,3.724,0,7.449,0,11.68,2.484,0,4.647-.012,6.809,0,3.488.023,5.316,1.908,5.362,5.275q.15,10.855,0,21.714c-.052,3.3-1.688,5.348-5.315,5.225C-699.544,195.9-701.086,195.939-703.043,195.939Z" transform="translate(-15780.702 -12784.367)" fill="#fff"  opacity=".5"/>
      <path id="Softdrinks_Icon-2" fill="url(#softsvgtest)" data-name="Softdrinks_Icon" d="M-703.043,195.939c-.855,10.007-1.695,19.507-2.473,29.013q-3.4,41.519-6.756,83.042-1.627,19.891-3.347,39.775c-.377,4.378-1.656,5.524-6.191,5.527q-44.206.028-88.411,0c-4.843,0-6.036-1.285-6.438-6.15q-4.048-49.049-8.153-98.091c-1.442-17.506-2.794-35.02-4.235-53.139-1.967,0-3.845.028-5.723-.007-2.8-.051-4.422-1.543-4.456-4.3-.1-7.753-.085-15.508-.028-23.261.023-2.992,1.848-4.443,4.7-4.576,2.288-.107,4.584-.022,7.191-.022,0-4.51-.029-8.5.008-12.488.045-4.906,1.57-6.457,6.557-6.468,15.64-.035,31.281-.013,46.92-.014h4.36c3.72-12.85,7.253-25.5,11.13-38.049a13.629,13.629,0,0,1,4.383-6.4c8.073-6.233,16.409-12.125,24.677-18.1,3.454-2.5,6.044-2.46,7.816-.031,1.748,2.4.939,5-2.415,7.45-7.4,5.413-14.926,10.666-22.179,16.27a12.942,12.942,0,0,0-4.229,5.992c-3.339,10.7-6.313,21.509-9.587,32.869h4.367q21.715,0,43.429,0c6.016.005,7.254,1.261,7.267,7.266.008,3.724,0,7.449,0,11.68,2.484,0,4.647-.012,6.809,0,3.488.023,5.316,1.908,5.362,5.275q.15,10.855,0,21.714c-.052,3.3-1.688,5.348-5.315,5.225C-699.544,195.9-701.086,195.939-703.043,195.939Z" transform="translate(-15780.702 -12784.367)" />
    </g>
    },
    {
      img: "waterbottle",
      name: "Water Bottle",
      qty: "50Kg",
      viewBox:"0 0 124.881 273.003",
      svgId:"waterbottlesvg",
      value:100,
      svg:<g id="Group_7971" data-name="Group 7971" transform="translate(18367 8679)">
      <path id="Path_36409" data-name="Path 36409" d="M-725.768,201.278c-9.417-12.78-8.765-23.384,1.845-35.649-12.123-13.178-9.625-26.137-1.893-35.9-5.454-4.473-6.307-10.559-6.505-17.306a79.98,79.98,0,0,1,16.682-52.385c4.218-5.548,7.977-11.443,12.135-17.038a13.6,13.6,0,0,0,3.006-9.268c-.272-5.413-.161-10.852-.045-16.276C-700.313,6.656-693.75.3-682.884.268q12.985-.041,25.971,0c10.689.042,17.381,6.566,17.661,17.312.132,5.035.234,10.088-.034,15.113a17.1,17.1,0,0,0,3.543,11.422C-628.734,54-621.579,63.86-615.6,74.368c7.861,13.816,8.939,29.277,7.454,44.767-.374,3.9-3.373,7.548-5.354,11.7,8.629,11.8,8.1,20.83-2.6,34.883,10.621,11.92,11.265,22.252,1.951,35.582,5.987,4.664,6.648,11.282,6.454,18.341-.224,8.134.011,16.28-.069,24.419-.166,16.949-12.218,29.059-29.106,29.122q-33.14.122-66.283,0c-16.837-.069-28.924-12.3-29.038-29.206-.06-8.656.182-17.319-.077-25.968C-732.465,211.467-731.075,205.734-725.768,201.278Z" transform="translate(-17634.615 -8679.245)" fill="#fff" opacity=".5"/>
      <path id="Path_36424" fill="url(#waterbottlesvg)" data-name="Path 36424" d="M-725.768,201.278c-9.417-12.78-8.765-23.384,1.845-35.649-12.123-13.178-9.625-26.137-1.893-35.9-5.454-4.473-6.307-10.559-6.505-17.306a79.98,79.98,0,0,1,16.682-52.385c4.218-5.548,7.977-11.443,12.135-17.038a13.6,13.6,0,0,0,3.006-9.268c-.272-5.413-.161-10.852-.045-16.276C-700.313,6.656-693.75.3-682.884.268q12.985-.041,25.971,0c10.689.042,17.381,6.566,17.661,17.312.132,5.035.234,10.088-.034,15.113a17.1,17.1,0,0,0,3.543,11.422C-628.734,54-621.579,63.86-615.6,74.368c7.861,13.816,8.939,29.277,7.454,44.767-.374,3.9-3.373,7.548-5.354,11.7,8.629,11.8,8.1,20.83-2.6,34.883,10.621,11.92,11.265,22.252,1.951,35.582,5.987,4.664,6.648,11.282,6.454,18.341-.224,8.134.011,16.28-.069,24.419-.166,16.949-12.218,29.059-29.106,29.122q-33.14.122-66.283,0c-16.837-.069-28.924-12.3-29.038-29.206-.06-8.656.182-17.319-.077-25.968C-732.465,211.467-731.075,205.734-725.768,201.278Z" transform="translate(-17634.615 -8679.245)"/>
    </g>
    },
    // {
    //   img: "Icon_replenish_2",
    //   name: "Meats",
    //   qty: "50Kg",
    //   viewBox:"0 0 298.576 274.941",
    //   svgId:"meatsvg",
    //   svg:<g id="Group_7956" data-name="Group 7956" transform="translate(17563 12700)">
    //   <path id="Path_36406" data-name="Path 36406" d="M-417.333,1591.281l-3.161,2.932c5.22,0,9.322.042,13.424-.017,3.373-.042,6.636.068,8.068,3.907,1.356,3.619-.975,5.831-3.195,8.059-8.178,8.2-16.229,16.509-24.6,24.5a11.258,11.258,0,0,1-6.983,2.941q-113.938.216-227.884-.008a11.139,11.139,0,0,1-6.966-2.958c-8.373-7.975-16.432-16.288-24.627-24.475-2.237-2.229-4.559-4.424-3.229-8.042s4.365-4.017,7.636-3.949a15.667,15.667,0,0,0,2.153-.424c-13.2-14.271-11.229-30.941-8.331-47.289,7.314-41.3,29.678-72.289,66.264-92.7,22.509-12.559,46.916-17.28,72.611-15.271,6.042.466,12.06,1.246,18.1,1.746a6.437,6.437,0,0,0,3.6-.661c10.153-5.924,21.466-8.441,32.712-11.076,5.661-1.322,10.644-3.568,14.593-7.924,5.246-5.788,11.382-9.61,19.619-9.085.89.051,2.2-1.034,2.754-1.941,2.873-4.686,5.72-9.407,8.2-14.305a6.223,6.223,0,0,0-.085-4.941c-5.746-11.051-3.144-22.67,6.627-28.551,9.729-5.848,21.085-2.907,28.085,7.135a4.906,4.906,0,0,0,3.254,1.814c11.983.941,20.339,9.526,20.1,20.831-.229,11.195-9.085,19.687-21.017,19.856a5.45,5.45,0,0,0-5.331,3.22c-2.381,4.322-5.119,8.458-7.28,12.881a6.659,6.659,0,0,0,.085,5.254c2.67,5.119,4.195,10.534,2.186,15.907-3.517,9.39-1.534,18.178,1.093,27.246,2.068,7.144,3.161,14.568,4.915,21.805a11.334,11.334,0,0,0,2.678,4.559c6.78,7.56,13.949,14.8,17.983,24.39C-393.655,1549.561-399.045,1574.434-417.333,1591.281Z" transform="translate(-16865.846 -14058.77)" fill="#fff" opacity=".5"/>
    //   <path id="Path_36406-2" fill="url(#meatsvg)" data-name="Path 36406" d="M-417.333,1591.281l-3.161,2.932c5.22,0,9.322.042,13.424-.017,3.373-.042,6.636.068,8.068,3.907,1.356,3.619-.975,5.831-3.195,8.059-8.178,8.2-16.229,16.509-24.6,24.5a11.258,11.258,0,0,1-6.983,2.941q-113.938.216-227.884-.008a11.139,11.139,0,0,1-6.966-2.958c-8.373-7.975-16.432-16.288-24.627-24.475-2.237-2.229-4.559-4.424-3.229-8.042s4.365-4.017,7.636-3.949a15.667,15.667,0,0,0,2.153-.424c-13.2-14.271-11.229-30.941-8.331-47.289,7.314-41.3,29.678-72.289,66.264-92.7,22.509-12.559,46.916-17.28,72.611-15.271,6.042.466,12.06,1.246,18.1,1.746a6.437,6.437,0,0,0,3.6-.661c10.153-5.924,21.466-8.441,32.712-11.076,5.661-1.322,10.644-3.568,14.593-7.924,5.246-5.788,11.382-9.61,19.619-9.085.89.051,2.2-1.034,2.754-1.941,2.873-4.686,5.72-9.407,8.2-14.305a6.223,6.223,0,0,0-.085-4.941c-5.746-11.051-3.144-22.67,6.627-28.551,9.729-5.848,21.085-2.907,28.085,7.135a4.906,4.906,0,0,0,3.254,1.814c11.983.941,20.339,9.526,20.1,20.831-.229,11.195-9.085,19.687-21.017,19.856a5.45,5.45,0,0,0-5.331,3.22c-2.381,4.322-5.119,8.458-7.28,12.881a6.659,6.659,0,0,0,.085,5.254c2.67,5.119,4.195,10.534,2.186,15.907-3.517,9.39-1.534,18.178,1.093,27.246,2.068,7.144,3.161,14.568,4.915,21.805a11.334,11.334,0,0,0,2.678,4.559c6.78,7.56,13.949,14.8,17.983,24.39C-393.655,1549.561-399.045,1574.434-417.333,1591.281Z" transform="translate(-16865.846 -14058.77)" />
    // </g>
    // },
    {
      img: "Icon_replenish_3",
      name: "Eggs",
      qty: "20 Dozens",
      viewBox:"0 0 204.84 273.941",
      svgId:"eggsvg",
      value:90,
      svg: <g id="Group_7954" data-name="Group 7954" transform="translate(16867 12708)">
      <path id="Path_9827" data-name="Path 9827" d="M-1487.792,373.208c-.162,22.762-5.142,48.423-20.782,71.027-16.76,24.221-40.3,36.917-69.343,39.925-20.538,2.127-40.407-.4-59.031-9.6-24.332-12.022-39.68-31.883-48.275-57.239-8.339-24.6-8.818-49.806-5.454-75.287a204.977,204.977,0,0,1,31.192-85.349c8.069-12.506,17.774-23.619,29.993-32.286,26.3-18.655,55.613-17.9,81.275,2.023,17.4,13.507,29.391,31.218,38.915,50.745C-1495.169,306.142-1487.975,336.783-1487.792,373.208Z" transform="translate(-15174.369 -12918.927)" fill="#fff" opacity=".5"/>
      <path id="Path_9827-2" fill="url(#eggsvg)" data-name="Path 9827" d="M-1487.792,373.208c-.162,22.762-5.142,48.423-20.782,71.027-16.76,24.221-40.3,36.917-69.343,39.925-20.538,2.127-40.407-.4-59.031-9.6-24.332-12.022-39.68-31.883-48.275-57.239-8.339-24.6-8.818-49.806-5.454-75.287a204.977,204.977,0,0,1,31.192-85.349c8.069-12.506,17.774-23.619,29.993-32.286,26.3-18.655,55.613-17.9,81.275,2.023,17.4,13.507,29.391,31.218,38.915,50.745C-1495.169,306.142-1487.975,336.783-1487.792,373.208Z" transform="translate(-15174.369 -12918.927)" />
    </g>
    },
    {
      img: "Icon_replenish_4",
      name: "Potato",
      qty: "120 Kg",
      viewBox:"0 0 296.23 273.941",
      svgId:"potatosvg",
      value:90,
      svg:  <g id="Group_7955" data-name="Group 7955" transform="translate(17217 12709)">
      <path id="Path_9829"  data-name="Path 9829" d="M-1951.7,5.29c-.864,74.549-48.709,142.52-118.976,168.828a180.965,180.965,0,0,1-65.335,11.674,112.729,112.729,0,0,1-107.769-82.564c-11.548-41.2,1.417-81.737,33.816-105.861,12.486-9.3,26.5-14.827,41.756-17.62,21.35-3.911,39.111-14.071,52.5-31.325,15-19.333,34.322-31.62,58.506-35.438a91.01,91.01,0,0,1,104.059,76C-1952.293-5.637-1952.164-.147-1951.7,5.29Z" transform="translate(-14969.07 -12620.856)" fill="#fff" opacity=".5"/>
      <g id="Group_7952"  data-name="Group 7952" transform="translate(-13 2)">
        <path id="Path_9829-2" fill="url(#potatosvg)" data-name="Path 9829" d="M-1951.7,5.29c-.864,74.549-48.709,142.52-118.976,168.828a180.965,180.965,0,0,1-65.335,11.674,112.729,112.729,0,0,1-107.769-82.564c-11.548-41.2,1.417-81.737,33.816-105.861,12.486-9.3,26.5-14.827,41.756-17.62,21.35-3.911,39.111-14.071,52.5-31.325,15-19.333,34.322-31.62,58.506-35.438a91.01,91.01,0,0,1,104.059,76C-1952.293-5.637-1952.164-.147-1951.7,5.29Z" transform="translate(-14956.07 -12622.855)" />
        <g id="Group_7945" data-name="Group 7945" transform="translate(534.941 -4)" opacity="0.25">
          <path id="Path_9830" data-name="Path 9830" d="M-1960.04,17.873A9.3,9.3,0,0,1-1969,27.156a9.312,9.312,0,0,1-9.446-9.227,9.179,9.179,0,0,1,9.38-9.237A9.224,9.224,0,0,1-1960.04,17.873Z" transform="translate(-15545.935 -12628.264)" fill="#fff" opacity=".5"/>
          <path id="Path_9831" data-name="Path 9831" d="M-2161.434,70.229a8.5,8.5,0,0,1-8.257-7.911,8.557,8.557,0,0,1,8.251-8.362A8.521,8.521,0,0,1-2153.4,62.1,8.529,8.529,0,0,1-2161.434,70.229Z" transform="translate(-15515.502 -12635.466)" fill="#fff" opacity=".5"/>
          <path id="Path_9832" data-name="Path 9832" d="M-2050.485,105.823a7.753,7.753,0,0,1-7.874-7.958,8.082,8.082,0,0,1,7.831-8.147,8.237,8.237,0,0,1,8.167,8.316A7.931,7.931,0,0,1-2050.485,105.823Z" transform="translate(-15533.218 -12641.156)" fill="#fff" opacity=".5"/>
          <path id="Path_9833" data-name="Path 9833" d="M-2019.014-29.309a7.134,7.134,0,0,1,6.481,7.362,7.027,7.027,0,0,1-7.313,6.5,6.93,6.93,0,0,1-6.454-7.332A7.028,7.028,0,0,1-2019.014-29.309Z" transform="translate(-15538.319 -12622.215)" fill="#fff" opacity=".5"/>
          <path id="Path_9834" data-name="Path 9834" d="M-2090.015,105.51a7,7,0,0,1,7.21,6.647,7.177,7.177,0,0,1-6.848,7.052,7.171,7.171,0,0,1-7.2-6.691A6.966,6.966,0,0,1-2090.015,105.51Z" transform="translate(-15527.092 -12643.669)" fill="#fff" opacity=".5"/>
          <path id="Path_9835" data-name="Path 9835" d="M-2081.6,87.083a6.792,6.792,0,0,1-6.823-6.99,6.665,6.665,0,0,1,7.131-6.62,6.709,6.709,0,0,1,6.648,6.71A6.847,6.847,0,0,1-2081.6,87.083Z" transform="translate(-15528.435 -12638.571)" fill="#fff" opacity=".5"/>
          <path id="Path_9836" data-name="Path 9836" d="M-1970.2,92.45a6.867,6.867,0,0,1-6.861,6.867,6.9,6.9,0,0,1-6.9-6.875,6.852,6.852,0,0,1,6.91-6.854A6.764,6.764,0,0,1-1970.2,92.45Z" transform="translate(-15545.057 -12640.5)" fill="#fff" opacity=".5"/>
          <path id="Path_9837" data-name="Path 9837" d="M-2176,137.806a7.031,7.031,0,0,1,6.391,7.422,7.03,7.03,0,0,1-7.224,6.591,7.014,7.014,0,0,1-6.336-7.837A7.042,7.042,0,0,1-2176,137.806Z" transform="translate(-15513.352 -12648.806)" fill="#fff" opacity=".5"/>
          <path id="Path_9838" data-name="Path 9838" d="M-2087.29,185.809a6.735,6.735,0,0,1-7.178,6.584,6.76,6.76,0,0,1-6.628-6.73,6.876,6.876,0,0,1,6.983-6.866A6.816,6.816,0,0,1-2087.29,185.809Z" transform="translate(-15526.418 -12655.331)" fill="#fff" opacity=".5"/>
        </g>
      </g>
    </g>
    },
  ];
  const facilityMangementData = [
    {
      id:"56703",
      img: "Bulb_Icon",
      name: "Daylight",
      signal:["zigbee"]
    },
    {
      id:"70956",
      img: "Doorlock_Icon",
      name: "Lock Doors",
      signal:["matter"]
    },
    {
      id:"10956",
      img: "Smartplug_Icon",
      name: "Smart plug",
      signal:["matter"]
    },
    {
      id:"25610",
      img: "Firealarm_Icon",
      name: "Emergency",
      signal:["matter"]
    },
    {
      id:"21458",
      img: "switch",
      name: "Smart Switch",
      signal:["matter"]
    },
    {
      id:"11458",
      img: "Sensor_Icon",
      name: "Motion Detect",
      signal:["matter"]
    },
  ];
  const wasteChartOPtion = useMemo(()=> {
    const day={
      "0":[10,120],
      "1":[20,50],
      "2":[30,10],
      "3":[50,140],
      "4":[70,20],
      "5":[60,20],
      "6":[10,20],
    }
    return {
    chart: {
      type: "solidgauge",
      backgroundColor: "transparent",
      events: {
        render: function () {
          this.reflow();
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },

    legend: {
      itemStyle: {
        fontSize: "var(--fs12)",
        fontWeight: 600,
        color: "red",
      },
      labelFormatter: function () {
        return (
          "<p class='legendTextPart' style='color:#fff; margin-bottom: 10rem; border:2px solid red;'>" +
          this.name +
          "</p>"
        );
      },
      symbolWidth: 10,
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      size: "100%",
      background: [
        {
          outerRadius: "95%",
          innerRadius: "88%",
          backgroundColor: "#EE456D80",
          borderWidth: 0,
        },
        {
          outerRadius: "68%",
          innerRadius: "62%",
          backgroundColor: "#415dea80",
          borderWidth: 0,
        },
      ],
    },
    yAxis: {
      lineWidth: 0,
      minorTickInterval: null,
      min: 0,
      max: 200,
      tickPixelInterval: 100,
      tickWidth: 0,
      labels: {
        y: 16,
        enabled: false,
      },
      title: {
        text: "",
      },
    },
    series: [
      {
        name: "Dry Waste",
        showInLegend: true,
        color: "#EE456D",
        data: [
          {
            name: "Dry Waste",
            radius: 100,
            innerRadius: 82,
            y: day[foodWasteDay][0],
            color: "#EE456D",
          },
        ],
        tooltip: {
          pointFormat: "{point.name}: <b>{0.5}</b> tonnes",
        },
      },
      {
        name: "Wet Waste",
        showInLegend: true,
        color: "#415CEA",
        data: [
          {
            name: "Wet Waste",
            radius: 73,
            innerRadius: 57,
            y: day[foodWasteDay][1],
            color: "#415CEA",
          },
        ],
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          pointFormat: "{point.name}: <b>{0.2}</b> tonnes",
        },
      },
    ],
    plotOptions: {
      solidgauge: {
        colorByPoint: false,
        dataLabels: {
          enabled: false,
        },
        linecap: "round",
        stickyTracking: false,
        rounded: true,
      },
    },
  }},[foodWasteDay])

  const locationData = [
    {
      location: "160 Broadway, New York, NY 10038",
      chartData: 664,
      guest: 20,
      worker: 4,
      drive: 89,
      order: 1,
      pickupd: 59,
      temperature: 73,
      humidity: 80.4,
      carbon: 26.2,
      predictiveEnergy:89
    },
    {
      location: "720 Imperial Ave, Calexico, CA, 92231",
      chartData: 800,
      guest: 53,
      worker: 59,
      drive: 5,
      order: 15,
      pickupd: 91,
      temperature: 9,
      humidity: 49.4,
      carbon: 0.6,
      predictiveEnergy:90
    },
    {
      location: "6875 Sandlake Rd, Orlando, FL, 32819",
      chartData: 836,
      guest: 93,
      worker: 40,
      drive: 91,
      order: 100,
      pickupd: 3,
      temperature: 22,
      humidity: 43.4,
      carbon: 0.42,
      predictiveEnergy:2
    },
    {
      location: "10207 Lakewood Blvd, Downey, CA, 90240",
      chartData: 493,
      guest: 71,
      worker: 33,
      drive: 44,
      order: 40,
      pickupd: 8,
      temperature: 29,
      humidity: 73.4,
      carbon: 0.41,
      predictiveEnergy:4
    },
    {
      location: "2320 Roll Drive, San Diego, CA, 92154",
      chartData: 254,
      guest: 79,
      worker: 13,
      drive: 72,
      order: 30,
      pickupd: 70,
      temperature: 3,
      humidity: 15.4,
      carbon: 0.63,
      predictiveEnergy:11
    },
    {
      location: "252 W Crawford St, Nogales, AZ, 85621",
      chartData: 796,
      guest: 72,
      worker: 35,
      drive: 21,
      order: 40,
      pickupd: 35,
      temperature: 22,
      humidity: 80.4,
      carbon: 0.14,
      predictiveEnergy:100
    },
  ];
  const selectedlocData = useMemo(() => {
    return locationData.filter((item, idx) => {
      if (idx === location) {
        return item;
      }
    })[0];
  }, [location]);
  const binLevelData = [
    {
      bin: "Kitchen",
      data: [10, 85],
    },
    {
      bin: "Dining",
      data: [85, 35],
    },
    {
      bin: "Parking",
      data: [35],
    },
  ];

  const loadChart = {
    chart: {
      type: "area",
      backgroundColor: "transparent ",
      events: {
        render: function () {
          this.reflow();
        },
      },
      animation: true,
    },
    title: "",
    accessibility: {
      point: {
        valueDescriptionFormat:
          "{index}. {point.category}, {point.y:,.1f}-, {point.percentage:.1f}U.",
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },

    yAxis: {
      gridLineColor: "transparent",
      lineColor: "#333",
      lineWidth: 1,
      labels: {
        format: "{value}U",
        style: {
          color: "#fff",
          fontSize: "var(--fs13)",
        },
      },
      title: {
        enabled: false,
      },
    },

    colors: ["#d14807", " #06865d", "#d2d1d3", "#1b38c2", "#c21d46"].reverse(),
    xAxis: {
      categories: [
        "10 Dec",
        "11 Dec",
        "12 Dec",
        "13 Dec",
        "14 Dec",
        "15 Dec",
        "16 Dec",
        "17 Dec",
      ],
      lineWidth: 1,

      labels: {
        style: {
          color: "#fff",
          fontSize: "var(--fs13)",
        },
      },
    },

    tooltip: {
      pointFormat: " <b>{point.percentage:.1f}U</b> ",
      split: true,
    },
    plotOptions: {
      area: {
        fillOpacity: 1,
        lineColor: null,
        stacking: "percent",
        marker: {
          enabled: false,
        },
      },
      series: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    series: [
      {
        name: "",
        color: "transparent",
        data: [7, 7.2, 7.5, 7.8, 7.5, 7.3, 7.1, 7],
      },
      {
        name: "USA",
        data: [0, 1, 1.5, 2.5, 3, 3.5, 3.6, 3],
      },
      {
        name: "EU",
        data: [0, 0, 1.5, 2.5, 3, 3.2, 3.7, 1.5],
      },
      {
        name: "India 1",
        data: [0, 0, 1.5, 2.5, 3, 3.5, 3, 2],
      },
      {
        name: "India 2",
        data: [0, 1.2, 2, 3, 3.5, 3, 2, 1],
      },
      {
        name: "India 3",
        data: [0, 0, 1.5, 2.5, 3, 2.5, 2.8, 1],
      },
    ],
  };

  const floatIcon=[
    {
      img:"Issue_Icon",
      placeholder:"Ice maker tray not closed"
    },
    {
      img:"Issue_Icon",
      placeholder:"Fryer oil Temp. exceeded"
    },
    {
      img:"info_Icon",
      placeholder:"Suspicious movement"
    },
    {
      img:"info_Icon",
      placeholder:"Staff Hairnet Missing"
    },
    {
      img:"info_Icon",
      placeholder:"Staff without Glove"
    }
  ];
  const kitchenEquipments = [
    {
      img: "pressure1",
      name: "15",
      qty: "pH Level",
    },
    {
      img: "pressure2",
      name: "52",
      qty: "Chlorine Residual",
    },
    {
      img: "MicrobialContaminants_Icon",
      name: "2",
      qty: "Microbial Contaminants",
    },
    {
      img: "TotalColiformCount_Icon",
      name: "1",
      qty: "Total Coliform Count",
    },
  ];

  const videoRef=useRef();
  useEffect(()=>{
    const demo=async()=>{
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true,audio:false });
        if(videoRef.current){
          videoRef.current.srcObject = stream;
          console.log(stream)
          videoRef.current.play();
        }
        // video.play();
      } catch (err) {
        console.log(err);
      }
    }
    demo()
  },[videoRef.current]) 

  const [widgetChart,setWidgetChart]=useState({
    chart: {
        type: 'column',
        backgroundColor: "transparent ",
    },
    title: {
        text: ''
    },
    xAxis: {
        type: 'category',
        labels: {
            autoRotation: [-45, -90],
            style: {
              color: "#fff",
              fontSize: "var(--fs13)",
            }
        }
    },
    yAxis: {
        min: 0,
        gridLineColor: "transparent",
      lineColor: "#333",
      lineWidth: 1,
        title: {
            text: ''
        },
        labels: {
          format: "{value}U",
          style: {
            color: "#fff",
            fontSize: "var(--fs13)",
          },
        },
    },
    legend: {
        enabled: false
    },
    plotOptions: {
      series: {
          borderWidth: 0,
          borderColor: 'black',
          pointWidth: 20
      }
  },
    credits: {
      enabled: false,
    },
    series: [{
        name: 'Widget',
        colors: [
            "#9b2226","#bb3e03","#ee9b00","#ca6702","#ae2012","#e9d8a6","#94d2bd","#0a9396","#005f73","#001219"
        ],
        colorByPoint: true,
        groupPadding: 0,
        
        data: [
            ['Fridge', 37.33],
            ['Oven', 31.18],
            ['Deep Fryer', 27.79],
            ['Coffe Brewer', 22.23],
            ['Grill', 21.91],
        ],
    }]
})

const analyticsChartOption = {
  chart: {
    type: "area",
    backgroundColor: "rgba(255, 255, 255, 0.0)",
  },
  title: {
    text: "",
    align: "left",
    floating: true,
    style: {
      fontWeight: "600",
      fontSize: "20px",
    },
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    categories: [
      "12 Dec",
      "13 Dec",
      "14 Dec",
      "15 Dec",
      "16 Dec",
      "17 Dec",
      "18 Dec",
    ],
    labels: {
      style: {
        fontSize: "clamp(8px , 13vw*100/1920, 13px)",
        color: "#ffffff75",
      },
    },
    gridLineWidth: 1,
    gridLineColor: "#ffffff50",

    // tickPositions: [0, 2, 4, 6, 8, 10],
  },
  yAxis: {
    // gridLineWidth: 1,
    title: {
      text: "",
    },
    gridLineColor: "#ffffff50",
    min: 0,
    max: 50,

    labels: {
      style: {
        fontSize: "clamp(8px , 13vw*100/1920, 13px)",
        color: "#ffffff75",
      },
      formatter: function () {
        return this.axis.defaultLabelFormatter.call(this) + "kg";
      },
    },
  },
  tooltip: {
    shared: true,
    headerFormat: "<b>{point.x}  Status  </b><br>",
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      connectNulls: true,
    },

    areaspline: {
      fillOpacity: 0.5,
    },
  },
  series: [
    {
      index: 1,
      name: "kg",
      color: "#fff",
      gridlinecolor: "#fffff00",
      data: [10, 20, 30, 20, 20, 30, 40],

      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, "#ffffff55"],
          [1, "#ffffff00"],
        ],
      },
      marker: {
        radius: 8,
      },
    },
  ],
};

  return (
    <div className="dashboard-section common-gap">
      <div className={`dashboard-section-left common-gap d-flex flex-column common-gap ${!widgetData.length?"w-100":""}`}>
        <div className="d-flex common-gap">
          <div className="first-section d-flex flex-column common-gap">
            <div className="left-item1 subcard-search">
              <DropDown>
                <DropDown.HeaderWithContent>
                  <button
                    type="button"
                    className="d-flex align-items-center justify-content-between w-100 search-btn"
                  >
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={require("../assets/images/Location_Icon.png")}
                        className="location-icon"
                        alt=""
                      />
                      <p className="general-text">{selectedlocData.location}</p>
                    </div>
                    <img
                      src={require("../assets/images/Dropdown_Icon.png")}
                      className="location-drop-icon"
                      alt=""
                    />
                  </button>
                </DropDown.HeaderWithContent>
                <DropDown.Body
                  value={locationData}
                  setlocation={setlocation}
                ></DropDown.Body>
              </DropDown>
            </div>
            <EnergyOverview value={selectedlocData} />
          </div>
          
          <div className="left-item2 subcard d-flex flex-column ">
            <div className="camers-section d-flex align-items-center gap-3 py-1">
              <img src={require("../assets/images/Camera_Icon.png")} alt="" className="camera-img"/>
              <div>
                <h2 className="general-text mb-0">Camera's</h2>
                <p className="general-text-grey d-flex align-items-center gap-3">
                  {
                    ["2 Online","0 Offline","2 Suspects"].map((item,idx)=>
                    <span key={idx} className="camera-status">{item}</span>
                    )
                  }
                </p>
              </div>
            </div>
                <p className=" general-text-grey d-flex align-items-center gap-3 pt-3">
                  Kitchen (2)
                </p>
            <div className="pt-2 d-flex justify-content-between flex-grow-1 kitchen-camera-alone">
                <div className="sub-tab-con">
                  
                  {
                    ["Img_1.gif","Img_2.gif"].map((item,idx)=>
                  <Modal key={idx}>
                  <Modal.ToggleButton>
                  <button
                    type="button"
                    className=" cam-item active"
                  >
                    <img
                      src={require(`../assets/images/${item}`)}
                      className="w-100 h-100 rounded overflow-hidden"
                      alt=""
                    />
                  </button>
                  </Modal.ToggleButton>
                  <Modal.Content title={"Crowd Alert"} subTitle={`Near Counter`} width={"clamp(25em, 40vw, 40em)"}>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                      <img src={require(`../assets/images/Crowdalert_Img.png`)} className="w-100" alt="" />
                    </div>
                  </Modal.Content>
                </Modal>
                      )
                  }
                </div>
            </div>
          </div>
        </div>
       {totalConsumption && <div className="w-100 d-flex gap-2">
          <div className="w-50 totalconsumption-top-con">
        <TotalConsumption/>
          </div>
        <div className="w-50 subcard d-flex flex-column">
                <h2 className="general-text">Safety Management</h2>
                <div className="w-100 h-100 d-flex flex-wrap gap-1 facility-con scroll-bar">
                  {facilityMangementData.map((item, idx) => (
                    <FacilityCard {...{...item,socket}} key={idx} />
                  ))}
                </div>
              </div>
        </div>}
        <div className="d-flex common-gap kitchen-360-main-con">
          <div className="subcard w-40 d-flex flex-column flex-grow-1">
            <div className="kitchen-header d-flex align-items-center justify-content-between">
              <p className="general-text">Kitchen 360</p>
              <button type="button">
                <img src={require("../assets/images/More_Icon.png")} className="more-icon" alt="" />
              </button>
            </div>
            <Tabs>
              <Tabs.Tab value={1} header="Layout">
                <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="tab position-relative ">
                  <img
                    src={require("../assets/images/kitchen.png")}
                    className="kitchen-diagram"
                    alt="kitchen"
                  />
                  <div className="floating-items">
                    {
                      floatIcon.map(({img,placeholder},idx)=>
                    <div className="items" key={idx}>
                      <div className={`image ${alert&&(img==="Issue_Icon"?"danger-active":"warning-active")}`}>
                        <img
                          src={require(`../assets/images/${img}.png`)}
                          alt="Issue_Icon"
                        />
                      </div>
                      <div className="hidden">
                        <p>{placeholder}</p>
                      </div>
                    </div>
                      )
                    }
                  </div>
                </div>
                </div>
              </Tabs.Tab>
              <Tabs.Tab value={2} header="Eqipment">
                <div className="sub-tab-con">
                  <button
                    type="button"
                    className="rounded overflow-hidden cam-item"
                  >
                    <img
                      src={require("../assets/images/Img_3.png")}
                      className="w-100 h-100"
                      alt=""
                    />
                  </button>
                </div>
              </Tabs.Tab>
              <Tabs.Tab value={3} header="Electrical">
                <div className="sub-tab-con">
                  <button
                    type="button"
                    className="rounded overflow-hidden cam-item"
                  >
                    <img
                      src={require("../assets/images/Img_4.png")}
                      className="w-100 h-100"
                      alt=""
                    />
                  </button>
                </div>
              </Tabs.Tab>
              <Tabs.Tab value={4} header="Pipe">
                <div className="sub-tab-con">
                  <button
                    type="button"
                    className="rounded overflow-hidden cam-item"
                  >
                    <img
                      src={require("../assets/images/Img_5.png")}
                      className="w-100 h-100"
                      alt=""
                    />
                  </button>
                </div>
              </Tabs.Tab>
            </Tabs>
          </div>
          <div className="w-60 d-flex flex-column  common-gap">
            <div className="subcard w-100">
              <HighEfficientDevices socket={socket}/>
            </div>
            <div className="d-flex common-gap flex-grow-1">
            {!totalConsumption &&<div className="w-40 subcard d-flex flex-column flex-grow-1">
                <h2 className="general-text">Safety Management</h2>
                <div className="w-100  d-flex flex-wrap gap-1 facility-con scroll-bar flex-grow-1">
                  {facilityMangementData.map((item, idx) => (
                    <FacilityCard {...{...item,socket}} key={idx} />
                  ))}
                </div>
              </div>}
              <div className="w-60 food-waste-con-res d-flex common-gap">
                <div className="w-60 d-flex flex-column subcard">
                  <h2 className="general-text">Food Waste</h2>
                  <div className="d-flex align-items-center flex-grow-1 ">
                    <div className="d-flex flex-column">
                      {["M", "T", "W","T", "F", "S", "S"].map((item, idx) => (
                        <button
                          type="button"
                          key={idx}
                          onClick={()=>setFoodWasteDay(idx)}
                          className={`rounded general-text-grey px-2 pb-1 ${
                            foodWasteDay === idx ? "text-white  bg-primary  py-1" : ""
                          }`}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                    <div className="waste-chart flex-grow-1">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={wasteChartOPtion}
                        containerProps={{
                          style: {
                            height: "100%",
                            width: "100%",
                            paddingInline: "0",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="subcard flex-grow-1">
                  <h2 className="general-text mb-0">Bin Levels</h2>
                  {binLevelData.map((item, idx) => {
                    return <Binlevel item={item} key={idx} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     {widgetData.length&& <div className="dashboard-section-right common-gap">
       {!totalConsumption && (widgetData.includes("TotalConsumption") &&<TotalConsumption/>)}
       {widgetData.includes("KitchenAirBalance")&& <div className="subcard item2 py-2 px-3 kitchen-con">
          <Tabs>
            <Tabs.Tab value={1} header="Kitchen Air Balance">
              <div className="kitchen-air-balance d-flex align-items-center m-auto ">
                <div className="d-flex align-items-center justify-content-center gap-2 w-50 up-con border-end">
                  <img
                    src={require("../assets/images/Uparrow_Icon.png")}
                    alt="Uparrow_Icon"
                    className="uparrow-icon"
                  />
                  <div>
                    <p>
                      <strong className="general-text">6000</strong>{" "}
                      <span className="general-text-grey text-white">Cfm</span>
                    </p>
                    <p className="general-text-grey">Exhaust flow</p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-2 w-50 ">
                  <img
                    src={require("../assets/images/Down_arrow.png")}
                    alt="Uparrow_Icon"
                    className="uparrow-icon"
                  />
                  <div>
                    <p>
                      <strong className="general-text">4800</strong>{" "}
                      <span className="general-text-grey text-white">Cfm</span>
                    </p>
                    <p className="general-text-grey">Move up air flow</p>
                  </div>
                </div>
              </div>
            </Tabs.Tab>
            <Tabs.Tab value={2} header="Water Quality">
              <div className="kitchen-air-balance d-flex align-items-center m-auto ">
              <div className="d-flex align-items-center justify-content-between w-100">
                {kitchenEquipments.map((item, idx) => (
                  <KitchenItem {...item} key={idx} styleClass={"water-wty-text"}/>
                ))}
              </div>
              </div>
            </Tabs.Tab>
          </Tabs>
        </div>}
        {widgetData.includes("ReplenishmentAlert")&& <div className="subcard item3">
          <h2 className="general-text">Replenishment Alert</h2>
          <div className="d-flex align-items-center justify-content-between replenishment-alert-con">
            {replenishmentData.map((item, idx) => (
              <Modal key={idx}>
              <Modal.ToggleButton>
                <div>
                 <KitchenItem {...item} key={idx} />
                </div>
              </Modal.ToggleButton>
              <Modal.Content title={item.name} width={"70%"}>
                <div className="d-flex ">
                  <div className="w-60 dustbin-con">
                    <div className="d-flex align-items-center gap-5">
                        <div >
                          <h2 className="general-text mb-0">{item.qty}</h2>
                          <p className="general-text-grey">Total</p>
                        </div>
                    </div>
                    <div className="line-chart-out-con h-100 pt-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h1 className="general-text">Daily {item.name} Status</h1>
                        <select name="" id="" className="form-select select-list mw-100 general-text bg-transparent px-2 py-1 rounded">
                          <option value="" className="">Last 2 Week</option>
                          <option value="" className="">Last 2 Week</option>
                        </select>
                      </div>
                      <div className="w-100 flex-grow-1 dust-bin-chart">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={analyticsChartOption}
                          containerProps={{
                            style: {
                              height: "100%",
                              width: "100%",
                              paddingInline: "0",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-40 d-flex align-items-center justify-content-center flex-column gap-4">
                <SvgIncrease value={item.value} svg={item.svg} svgId={item.svgId} viewBox={item.viewBox}>
                  {item.svg}
                </SvgIncrease>
                <button type="button"className="general-text-grey rounded req-btn py-2 px-4">
                  Request to Fill
                </button>
                  </div>
                </div>
              </Modal.Content>
            </Modal>
            ))}
          </div>
        </div>}
        {widgetData.includes("PredictiveMaintenance")&& <PredictiveMaintenance/>}
      </div>}
    </div>
  );
};

export default Dashboard;
