import { useMemo } from "react";

const Temperature = ({value}) => {

    const color=useMemo(()=>{
        if(value<=45){
          return "#13d906"
        }
        if(value>45&&value<=75){
          return " var(--color7)"
        }
        if(value>75&&value<=100){
          return "red"
        }
    },[value])
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30.342" height="51.514" viewBox="0 0 30.342 51.514">
            <defs>
                    <linearGradient
                      id="linear-gradient-temp"
                      x1="0.5"
                      y1={`0.${100-value}`}
                      x2="0.5"
                      y2="0.99"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop
                        offset="0"
                        stopColor="#cccc"
                      ></stop>
                      <stop
                        offset="0"
                        // stopColor="red"
                        stopColor={color}
                        className="stop_color"
                      ></stop>
                      <stop
                        offset="0.505"
                        // stopColor="red"
                        stopColor={color}
                        className="stop_color"
                      ></stop>
                      <stop
                        offset="1"
                        // stopColor="red"
                        stopColor={color}
                        className="stop_color"
                      ></stop>
                    </linearGradient>
                  </defs>
             <g id="Group_7795" data-name="Group 7795" transform="translate(19698.223 17344.512)">
               <path id="Path_5543" data-name="Path 5543" d="M-714.064-259.923c0,3.679.009,7.359-.01,11.038a1.416,1.416,0,0,0,.534,1.185,12.163,12.163,0,0,1-4.237,20.709,12.165,12.165,0,0,1-15.8-10.057,12.023,12.023,0,0,1,4.151-10.786,1.489,1.489,0,0,0,.39-1c.022-7.139.011-14.279.019-21.418a7.483,7.483,0,0,1,5.259-7.291,7.4,7.4,0,0,1,9.615,6.436c.161,3.721.032,7.454.032,11.181Zm-3,.912h0c0-3.8.025-7.6-.008-11.4a4.455,4.455,0,0,0-5.85-4.216A4.462,4.462,0,0,0-726-270.216q-.011,11.073.012,22.145a2.529,2.529,0,0,1-1.105,2.283,9.078,9.078,0,0,0-2.823,10.877,9.221,9.221,0,0,0,9.755,5.4,9.236,9.236,0,0,0,7.506-6.9,9.1,9.1,0,0,0-3.482-9.5,2.06,2.06,0,0,1-.943-1.838C-717.051-251.507-717.067-255.259-717.067-259.01Z" transform="translate(-18964.549 -17066.615)" fill="#fff"/>
               <path id="Path_5544" data-name="Path 5544" d="M-574.946-195.618c.729,0,1.457-.013,2.185,0a1.562,1.562,0,0,1,1.628,1.516,1.539,1.539,0,0,1-1.581,1.49q-2.294.04-4.589,0a1.491,1.491,0,0,1-1.536-1.514,1.52,1.52,0,0,1,1.56-1.493c.049,0,.1,0,.146,0Z" transform="translate(-19096.75 -17136.863)" fill="#fff"/>
               <path id="Path_5545" data-name="Path 5545" d="M-575.015-151.411c-.753,0-1.506.013-2.258,0a1.511,1.511,0,0,1-1.566-1.409,1.43,1.43,0,0,1,1.379-1.573c1.624-.064,3.253-.062,4.878-.009a1.478,1.478,0,0,1,1.442,1.535,1.579,1.579,0,0,1-1.616,1.458h-2.258Z" transform="translate(-19096.744 -17172.018)" fill="#fff"/>
               <path id="Path_5546" data-name="Path 5546" d="M-574.969-236.869c.755,0,1.511-.013,2.266,0a1.559,1.559,0,0,1,1.568,1.434,1.47,1.47,0,0,1-1.437,1.543q-2.41.076-4.823,0a1.444,1.444,0,0,1-1.44-1.526,1.521,1.521,0,0,1,1.6-1.459h2.266Z" transform="translate(-19096.748 -17101.641)" fill="#fff"/>
               <path id="Path_5547" data-name="Path 5547" d="M-575.053-110.13c-.731,0-1.462.014-2.193,0a1.5,1.5,0,0,1-1.588-1.468,1.481,1.481,0,0,1,1.526-1.527c1.534-.031,3.07-.029,4.6,0a1.531,1.531,0,0,1,1.572,1.5,1.55,1.55,0,0,1-1.582,1.493C-573.493-110.114-574.273-110.13-575.053-110.13Z" transform="translate(-19096.75 -17207.273)" fill="#fff"/>
               <path id="Path_5548" data-name="Path 5548" fill="url(#linear-gradient-temp)" d="M-687.854-184.47q0-4.749,0-9.5a1.531,1.531,0,0,1,1.422-1.708,1.516,1.516,0,0,1,1.614,1.523c.036.607.01,1.217.01,1.826q0,8.658-.011,17.315a.832.832,0,0,0,.647.942,6.073,6.073,0,0,1,3.921,6.339,6.144,6.144,0,0,1-4.711,5.328,6.143,6.143,0,0,1-7.215-4.1,6.093,6.093,0,0,1,3.718-7.585.776.776,0,0,0,.615-.882C-687.867-178.138-687.854-181.3-687.854-184.47Z" transform="translate(-18999.748 -17136.814)"/>
             </g>
           </svg>
  )
}

export default Temperature