import { BrowserRouter,Routes,Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard"; 
import SocketProvider from "./context/SocketProvider";
import "./styles/styles.scss";


function App() {
  return (
    <BrowserRouter>
    <SocketProvider>
      <Routes>
       <Route path="/" element={<Layout/>}>
          <Route index path="/" element={<Dashboard/>}/>
          <Route path="*" element={<Dashboard />} />
       </Route>
      </Routes>
    </SocketProvider>
    </BrowserRouter> 
  );
}

export default App;
