import { cloneElement, createContext, useContext, useState } from "react"
import { createPortal } from "react-dom";

const ModalContext=createContext();
const Modal = ({children}) => {
    const [isOpen,setIsOpen]=useState(false);
  return (
    <ModalContext.Provider value={{isOpen,setIsOpen}}>
        {children}
    </ModalContext.Provider>
  )
}

const ToggleButton = ({children}) => {
  const {setIsOpen}=useContext(ModalContext);
  return cloneElement(children,{onClick:()=>{setIsOpen(true)}})
}

 const Content = ({children,title,subTitle,width="50%"}) => {
  const {isOpen,setIsOpen}=useContext(ModalContext);
    return isOpen&& createPortal(
      <>
    <section className="modal-section py-4 px-5 rounded" style={{width}}>
      <div className="modal-cust-header  d-flex align-items-start justify-content-between border-bottom">
        <div>
          <h2>{title}</h2>
          <p className="general-text-grey">{subTitle}</p>
        </div>
        <button type="button" onClick={()=>setIsOpen(false)}><img src={require("../assets/images/Close_Icon.png")} alt="Close_Icon" /></button>
      </div>
      <div className="modal-cust-body py-3">
         {children}
      </div>
    </section>
      <div className="cust-modal-backdrop " onClick={()=>setIsOpen(false)}> </div>
      </>
    ,document.body)
 }

 Modal.ToggleButton=ToggleButton
 Modal.Content=Content

export default Modal