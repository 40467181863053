import { createContext, useState } from "react";
import { useEffect } from "react";
import { socket } from "../utils/socket";

export const SocketContext = createContext();
const SocketProvider = ({ children }) => {
  const [socketConnect, setSocketConnect] = useState(false);

  useEffect(() => {
    const connect = (data) => {
      console.log(data);
      setSocketConnect(true);
    };
    // socket.connect();
    socket.on("connected", connect);

    return () => {
      socket.off("connected", connect);
      socket.disconnect();
    };
  }, []);
  return (
    <SocketContext.Provider value={{ socket, socketConnect, setSocketConnect }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
