
const TotalConsumption = () => {
  return (
    <div className="subcard item1">
          <div className="d-flex align-items-center gap-3">
            <img
              src={require("../../assets/images/TotalConsuption_Icon.png")}
              alt="TotalConsuption_Icon"
              className="TotalConsuption_Icon"
            />
            <h2 className="general-text mb-0">Total Consumption</h2>
          </div>
          <div className="d-flex align-items-center gap-3 pt-2">
            <p className="d-flex align-items-center gap-2 flex-grow-1">
              {[6, 8, 9, 6, 6, 6].map((item, idx) => (
                <span className="consumption-num cons-yellow" key={idx}>
                  {item}
                </span>
              ))}
              <span className="consumption-num general-text">.</span>
              {[6, 0].map((item, idx) => (
                <span className="consumption-num cons-red" key={idx}>
                  {item}
                </span>
              ))}
            </p>
            <p className="kw-h">Kw-h</p>
          </div>
        </div>
  )
}

export default TotalConsumption