import Accordion from "../Accordion"
import Tabs from "../Tabs"
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Modal from "../Modal";
import { memo, useState } from "react";

const PredictiveMaintenance = () => {
    const loadChart = {
        chart: {
          type: "area",
          backgroundColor: "transparent ",
          events: {
            render: function () {
              this.reflow();
            },
          },
          animation: true,
        },
        title: "",
        accessibility: {
          point: {
            valueDescriptionFormat:
              "{index}. {point.category}, {point.y:,.1f}-, {point.percentage:.1f}U.",
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
    
        yAxis: {
          gridLineColor: "transparent",
          lineColor: "#333",
          lineWidth: 1,
          labels: {
            format: "{value}U",
            style: {
              color: "#fff",
              fontSize: "var(--fs13)",
            },
          },
          title: {
            enabled: false,
          },
        },
    
        colors: ["#d14807", " #06865d", "#d2d1d3", "#1b38c2", "#c21d46"].reverse(),
        xAxis: {
          categories: [
            "10 Dec",
            "11 Dec",
            "12 Dec",
            "13 Dec",
            "14 Dec",
            "15 Dec",
            "16 Dec",
            "17 Dec",
          ],
          lineWidth: 1,
    
          labels: {
            style: {
              color: "#fff",
              fontSize: "var(--fs13)",
            },
          },
        },
    
        tooltip: {
          pointFormat: " <b>{point.percentage:.1f}U</b> ",
          split: true,
        },
        plotOptions: {
          area: {
            fillOpacity: 1,
            lineColor: null,
            stacking: "percent",
            marker: {
              enabled: false,
            },
          },
          series: {
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false,
                },
              },
            },
          },
        },
        series: [
          {
            name: "",
            color: "transparent",
            data: [7, 7.2, 7.5, 7.8, 7.5, 7.3, 7.1, 7],
          },
          {
            name: "USA",
            data: [0, 1, 1.5, 2.5, 3, 3.5, 3.6, 3],
          },
          {
            name: "EU",
            data: [0, 0, 1.5, 2.5, 3, 3.2, 3.7, 1.5],
          },
          {
            name: "India 1",
            data: [0, 0, 1.5, 2.5, 3, 3.5, 3, 2],
          },
          {
            name: "India 2",
            data: [0, 1.2, 2, 3, 3.5, 3, 2, 1],
          },
          {
            name: "India 3",
            data: [0, 0, 1.5, 2.5, 3, 2.5, 2.8, 1],
          },
        ],
      };
      const [widgetChart,setWidgetChart]=useState({
        chart: {
            type: 'column',
            backgroundColor: "transparent ",
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'category',
            labels: {
                autoRotation: [-45, -90],
                style: {
                  color: "#fff",
                  fontSize: "var(--fs13)",
                }
            }
        },
        yAxis: {
            min: 0,
            gridLineColor: "transparent",
          lineColor: "#333",
          lineWidth: 1,
            title: {
                text: ''
            },
            labels: {
              format: "{value}U",
              style: {
                color: "#fff",
                fontSize: "var(--fs13)",
              },
            },
        },
        legend: {
            enabled: false
        },
        plotOptions: {
          series: {
              borderWidth: 0,
              borderColor: 'black',
              pointWidth: 20
          }
      },
        credits: {
          enabled: false,
        },
        series: [{
            name: 'Widget',
            colors: [
                "#9b2226","#bb3e03","#ee9b00","#ca6702","#ae2012","#e9d8a6","#94d2bd","#0a9396","#005f73","#001219"
            ],
            colorByPoint: true,
            groupPadding: 0,
            
            data: [
                ['Fridge', 37.33],
                ['Oven', 31.18],
                ['Deep Fryer', 27.79],
                ['Coffe Brewer', 22.23],
                ['Grill', 21.91],
            ],
        }]
    })
    
  return (
    <div className="subcard item4 flex-grow-1">
          <Accordion>
            <Accordion.Content title="Daily Load" value={1}>
            <div className="w-100 h-100 d-flex flex-column">
            <Tabs>
            <Tabs.Tab value={1} header="Day energy">
            <div className="w-100 load-chart ">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={loadChart}
                  containerProps={{
                    style: {
                      height: "100%",
                      width: "100%",
                      paddingInline: "0",
                    },
                  }}
                />
              </div>
            </Tabs.Tab>
            <Tabs.Tab value={2} header="Equipment">
              <div className="w-100 load-chart">
              <HighchartsReact
                  highcharts={Highcharts}
                  options={widgetChart}
                  containerProps={{
                    style: {
                      height: "100%",
                      width: "100%",
                      paddingInline: "0",
                    },
                  }}
                />
              </div>
            </Tabs.Tab>
             </Tabs>
            </div>
            </Accordion.Content>
            <Accordion.Content title="Predictive Maintenance" value={2}>
              <div className="d-flex align-items-center flex-wrap gap-3 py-3">
                {
                  [11,23,13,14,15,16,17].map((item,idx)=>
                <Modal key={idx}>
                  <Modal.ToggleButton>
                <div  className="date px-4 py-1 d-flex align-items-center justify-content-center flex-column rounded-2 position-relative">
                  <p>{item}</p>
                  <span>Dec</span>
                  <div className="count position-absolute d-flex align-items-center justify-content-center rounded-circle top-0 start-100 translate-middle">
                    <small>6</small>
                  </div>
                </div>
                  </Modal.ToggleButton>
                  <Modal.Content title={"Predictive Maintenance"} subTitle={`${item}Dec`} >
                    <div className="d-flex align-items-center justify-content-center gap-3">
                      <img src={require("../../assets/images/EquipmentHealthCheck_Icon.png")} className="equipment-img" alt="" />
                      <div>
                        <p className="general-text">Equipment Health Check</p>
                        <p className="general-text-grey">Increased wear on your slicer's blades detected. Consider replacing blades to maintain optimal slicing performance.</p>
                      </div>
                    </div>
                  </Modal.Content>
                </Modal>
                    )
                }
              </div>
            </Accordion.Content>
            <Accordion.Content title="Cleaning & Sanitizing" value={3}>
              <div className="d-flex justify-content-evenly">
                <div className="d-flex align-items-center justify-content-between flex-column ">
                {
                  ["T1","T2","T3"].map((item,idx)=>
                <div key={idx} className={`d-flex align-items-center justify-content-center flex-column food-table-con ${item==="T3"?"active":""}`}>
                  <img src={require(`../../assets/images/${item}.png`)} alt="table images" className="horizontal-img"/>
                  <p className="general-text">{item}</p>
                </div>
                    )
                }
                </div>
                <div className="d-flex align-items-center justify-content-between flex-column gap-3">
                {
                  ["T4","T5"].map((item,idx)=>
                <div key={idx} className={`d-flex align-items-center justify-content-center flex-column food-table-con ${item==="T4"?"active":""}`}>
                  <img src={require(`../../assets/images/${item}.png`)} alt="table images" className="vertical-img"/>
                  <p className="general-text">{item}</p>
                </div>
                    )
                }
                </div>
                <div className="d-flex align-items-center justify-content-between flex-column gap-3">
                {
                  ["T6","T7"].map((item,idx)=>
                <div key={idx} className={`d-flex align-items-center justify-content-center flex-column food-table-con ${item==="T3"?"active":""}`}>
                  <img src={require(`../../assets/images/${item}.png`)} alt="table images" className="vertical-img"/>
                  <p className="general-text">{item}</p>
                </div>
                    )
                }
                </div>
                <div className="d-flex align-items-center justify-content-between flex-column ">
                {
                  ["T8","T9","T10"].map((item,idx)=>
                <div key={idx} className={`d-flex align-items-center justify-content-center flex-column food-table-con ${item==="T10"?"active":""}`}>
                  <img src={require(`../../assets/images/${item}.png`)} alt="table images" className="horizontal-img"/>
                  <p className="general-text">{item}</p>
                </div>
                    )
                }
                </div>
              </div>
            </Accordion.Content>
            <Accordion.Content title="Expiry Reminder" value={4}>
              <ul className="expriy-list py-1 mb-0">
                {
                  ["Canned seafood expiring in another 3 days","steak expiring in 2 days","Dried Egg Whites getting expired in a day"].map((item,idx)=>{
                    return <li key={idx} className="d-flex align-items-center gap-2 py-1">
                        <img src={require("../../assets/images/Star_Icon.png")} className="star-img" alt="star"/>
                        <p className="general-text-grey">{item}</p>
                      </li>
                  })
                }
                </ul>
            </Accordion.Content>
            <Accordion.Content title="Leak Alerts" value={5}>
              {/* <div className="d-flex align-items-center flex-wrap gap-3 py-3">
                {
                  [11,23,13,14,15,16,17].map((item,idx)=>
                <div key={idx} className="date px-4 py-2 d-flex align-items-center justify-content-center flex-column rounded-2 position-relative">
                  <p>{item}</p>
                  <span>Dec</span>
                  <div className="count position-absolute d-flex align-items-center justify-content-center rounded-circle top-0 start-100 translate-middle">
                    <small>6</small>
                  </div>
                </div>
                    )
                }
              </div> */}
            </Accordion.Content>
          </Accordion>
        </div>
  )
}

export default memo(PredictiveMaintenance)