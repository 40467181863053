import { useContext, useEffect, useState } from "react";
import SwitchKey from "../SwitchKey";
import KitchenCon from "./KitchenCon";
import { SocketContext } from "../../context/SocketProvider";

const HighEfficientDevices = () => {
  const {socket}=useContext(SocketContext);
  useEffect(() => {
    const devicesData = (data) => {
      // console.log("data:", data);
      setKitchenEquipments((prev) =>
        prev.map((item) => {
          if (item.id === data.id) {
            return { ...item, name: `${data.qty}%` };
          } else {
            return item;
          }
        })
      );
    };
    socket.on("high-efficient-device", devicesData);
    return () => {
      socket.off("high-efficient-device", devicesData);
    };
  }, []);
  const [kitchenEquipments, setKitchenEquipments] = useState([
    {
      id: "837070",
      img: "Icon_1",
      name: "25.6%",
      qty: "Fridge",
    },
    {
      id: "593637",
      img: "Icon_2",
      name: "52.3%",
      qty: "Oven",
    },
    {
      id: "479578",
      img: "Icon_3",
      name: "73%",
      qty: "Dishwasher",
    },
    {
      id: "592906",
      img: "Icon_4",
      name: "53.1%",
      qty: "Deep Fryer",
    },
    {
      id: "336850",
      img: "Icon_5",
      name: "48%",
      qty: "Coffe Brewer",
    },
    {
      id: "721953",
      img: "Icon_6",
      name: "15%",
      qty: "Grill",
    },
    {
      id: "592906",
      img: "Icon_4",
      name: "53.1%",
      qty: "Deep Fryer",
    },
    {
      id: "336850",
      img: "Icon_5",
      name: "48%",
      qty: "Coffe Brewer",
    },
    {
      id: "721953",
      img: "Icon_6",
      name: "15%",
      qty: "Grill",
    },
  ]);
  const [actulData,setActualData]=useState([]);
  const [checkAll,setCheckAll]=useState(false);
  const facilityCardToggle=(e)=>{
    const {checked}=e.target;
    setCheckAll(checked);
  if(checked){
    setActualData(kitchenEquipments.map(({id})=>id))
  }
  else{
    setActualData([])
  }
  }
  
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-4">
        <p className="general-text">High Energy Kitchen Equipments</p>
        <SwitchKey rotate="rotate(90deg)" addClass="all-toggle" facilityCardToggle={facilityCardToggle} checked={checkAll} />
        </div>
        <div className="d-flex align-items-center gap-4">
          <img src={require("../../assets/images/mqtt.png")} className="symbols-icon" alt="" />
          <img src={require("../../assets/images/wifi.png")} className="symbols-icon-small" alt="" />
        </div>
        <div className="d-flex align-items-center gap-2 ">
          <button
            type="button"
            className="bg-primary  rounded px-2 py-1 general-text-grey text-white"
          >
            Add Device +
          </button>
          <button type="button">
            <img
              src={require("../../assets/images/More_Icon.png")}
              className="more-icon"
              alt=""
            />
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center  kitchen-sub-con flex-wrap scroll-bar">
        {kitchenEquipments.map((item, idx) => (
          <KitchenCon key={idx} item={item} kitchenEquipments={kitchenEquipments} setActualData={setActualData} actulData={actulData} setCheckAll={setCheckAll}/>
        ))}
      </div>
    </>
  );
};

export default HighEfficientDevices;
