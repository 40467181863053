import { Link, NavLink } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../context/AppProvider";

const Sidebar = () => {
  const widgetOne = [
    "Kitchen 360",
    "Camera",
    "Equipment Energy Consumption",
    "Facility Management",
    "Food Waste",
    "Leak Alerts",
    "Consumption Comparsion",
    "Cleaning & Sanitizing",
    "Total Consumption",
    "Predictive Maintenance",
    "Kitchen Air Balance",
    "Replenishment Alert",
  ];
  const { setWidgetData,widgetData } = useContext(AppContext);
  const [isOpen,setOpen]=useState(false);
  const [position,setPosition]=useState();
  const widgetInput = (e) => {
    if (e.target.checked) {
      setWidgetData((prev) => [...prev, e.target.value]);
    } else {
      setWidgetData((prev) => prev.filter((item) => item !== e.target.value));
    }
  };
  const disableData = [
    "Kitchen360",
    "Camera",
    "EquipmentEnergyConsumption",
    "FacilityManagement",
    "FoodWaste",
    "ConsumptionComparsion",
    "Cleaning&Sanitizing",
    "LeakAlerts",
    "BinLevels",
  ];
  const navData = [
    { img: "Home_Icon", url: "/" },
    { img: "Waste_Icon", url: "/waste" },
    { img: "Energy_Icon", url: "/energy" },
    { img: "Water_Icon", url: "/water" },
    { img: "Inventory_Icon", url: "/inventory" },
  ];
  const toggleCustomDropDown=(e)=>{
    const {top,left,right,bottom}=e.currentTarget.getBoundingClientRect();
    setPosition({top,left,right,bottom})
    setOpen(true)
  }
  return (
    <>
    <aside className="d-flex flex-column">
      <Link to="/" className="logo">
        <img src={require("../assets/images/logo.png")} alt="logo" />
      </Link>
      <nav className="flex-grow-1 d-flex flex-column align-items-center justify-content-between">
        <ul>
          {navData.map(({ img, url }, idx) => (
            <li key={idx}>
              <NavLink to={url}>
                {({ isActive }) => (
                  <>
                    {!isActive ? (
                      <img
                        src={require(`../assets/images/${img}.png`)}
                        alt="icon"
                      />
                    ) : (
                      <img
                        src={require(`../assets/images/${img}1.png`)}
                        alt="icon"
                      />
                    )}
                  </>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="d-flex flex-column align-items-center gap-3 w-100">
          <div className="d-flex align-items-center flex-column gap-5 w-100">
              <button type="button" className="general-text add-widget-btn" onClick={toggleCustomDropDown}>
                <img src={require("../assets/images/addwidget.png")}  alt="" />{" "}
              </button>
          <button type="button" className="notification-img">
            <img
              src={require("../assets/images/Notification_Icon.png")}
              alt="Notification_Icon"
            />
            <span className="general-text">6</span>
          </button>
          </div>
          <Link to="/">
            <img
              className="user-image"
              src={require("../assets/images/Profile_img.png")}
              alt="user"
            />
          </Link>
        </div>
      </nav>
    </aside>
    {
      isOpen ?
    <div className="add-widget-drop">
    <ul className="add-widget shadow-lg position-absolute rounded" style={{bottom:`${window.innerHeight-(position.bottom+25)}px`,left:`${position.right+20}px`}}>
    <p className="general-text-grey mb-2">Slected 6 Widgets</p>
    <div className="w-100 d-grid row widget-con gap-3">
      {widgetOne.map((item, idx) => {
        return (
          <li
            key={idx}
            className="d-flex align-items-center justify-content-start "
          >
            <label
              htmlFor={item.replaceAll(/\s/g, "")}
              className="d-flex align-items-center gap-2"
            >
              <input
                type="checkbox"
                disabled={disableData.includes(
                  item.replaceAll(/\s/g, "")
                )}
                checked={widgetData.includes(item.replaceAll(/\s/g, ""))}
                id={item.replaceAll(/\s/g, "")}
                value={item.replaceAll(/\s/g, "")}
                className="d-none widget-input"
                onChange={widgetInput}
              />
              <div className="check-box-con"></div>
              <p className="general-text">{item}</p>
            </label>
          </li>
        );
      })}
    </div>
    </ul>
    <div className="cust-drop-backdrop"  onClick={()=>setOpen(false)}></div>
    </div>:null
    }
    </>
  );
};

export default Sidebar;
